import React from 'react';
import './App.css';
import PrimaryLayout from './primaryLayout';

function App() {
  return (
    <div className="App">
      <PrimaryLayout></PrimaryLayout>
    </div>
  );
}

export default App;
