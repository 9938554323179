import React, { Component } from 'react';
import { Link,NavLink  } from 'react-router-dom';
import Logo from '../images/logo.png';

class Header extends Component{
    constructor(props){
        super(props);
        this.state={
            current:"home"
        };
    }

    render(){   

        return (<div id='header'>
            <div className='main-box'>
                <div className='clearfix'>
                    <div className="left">
                        <Link to='/' className='logo'>
                            <img src={Logo} alt=''/>
                        </Link>
                        <NavLink to='/' activeClassName='on' exact>首页</NavLink>
                        <NavLink  to='/apply' activeClassName='on'>应用</NavLink >
                    </div>
                    <div className='right'>
                        <a href='https://dtbms.com/erpentry/account/login' style={{marginRight:20}}>登录</a>             
                        <a href='https://dtbms.com/erpentry/purchaseproduction/items?Channelid=1005&lang=zh-CN' className='button' style={{backgroundColor:'#fa9f01'}}>免费试用</a>                        
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default Header;