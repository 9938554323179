import React from 'react';
import Weixin from '../images/weixin.png';

const Footer=()=>{
   return (<div id='footer'>
        <div className='main-box'>
            <div className='clearfix'>
                <div className='left'>
                    <p>电话：021-60190959</p>
                    <p>地址：上海市闵行区紫星路588号2号楼322室</p>
                    <p>邮箱：sales@dynamictier.com</p>
                </div>
                <div className='right'>
                    <img alt='' src={Weixin} />
                </div>
           </div>
            <div className='bottom'><p>©Copyright 2016-{(new Date()).getFullYear()} 上海代塔信息科技有限公司  沪ICP备16006792号</p></div>
       </div>
   </div>);
};

export default Footer;