import React, { Component } from 'react';
import { Link  } from 'react-router-dom';
class Home extends Component{
    render(){
        return (<div id='home-page'>
            <div className='bg1'>
                <img className='img' src={process.env.PUBLIC_URL+'/ggz.png'} alt='' />
                <div className='div'>
                    <a className='first_a' href='https://www.dtbms.com/erpentry/purchaseproduction/items?Channelid=1005&lang=zh-CN' >免费试用</a>
                </div>
            </div>
            <div className='bg2' >
                <div className='div' >
                    <img className='img' src={process.env.PUBLIC_URL+'/img3.png'} alt=''/>
                </div>
            </div>
            <div className='bg3' >
                <div className='div1' >
                    <img className='img' src={process.env.PUBLIC_URL+'/img4.png'} alt=''/>
                </div>
                <div className='div2'>
                    <Link to='/apply' className='first_a'>了 解 更 多</Link>
                </div>
            </div>
            <div className='bg4'>
                <img className='img' src={process.env.PUBLIC_URL+'/img5.png'} alt=''/>
                <div className='div' >
                    <a className='first_a' href='https://www.dtbms.com/erpentry/purchaseproduction/items?Channelid=1005&lang=zh-CN'>免费试用</a>
                </div>
            </div>

            <div className='bg5' >
                <div className='div' >
                    <img className='img' src={process.env.PUBLIC_URL+'/img6.png'} alt=''/>
                </div>
            </div>
        </div>);
    }
}

export default Home;