import React, { Component } from 'react';
import {
    HashRouter as Router,
    Switch,
    useLocation,
    Route    
  } from "react-router-dom";
import ScrollToTop from './ScrollToTop';
import Footer from './footer';
import Header from './header';
import {Home,Apply} from './pages';
  
class PrimaryLayout extends Component{
    render(){        
        return (<div>            
            <Router> 
                <Header></Header>                                               
                <div>
                    <ScrollToTop>
                        <Switch>   
                            <Route exact path="/">
                                <Home />
                            </Route>    
                            <Route path="/apply">
                                <Apply />
                            </Route>
                        </Switch>
                    </ScrollToTop>
                </div>
                <Footer></Footer>
            </Router>
        </div>);
    }
}

export default PrimaryLayout;