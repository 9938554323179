import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class Apply extends Component{
    render(){
        let dipslayBlock=()=>{
            let res=[];
            for(let i=1;i<=6;i++){
                res.push(<div className={'box '+(i%2===0?'grey':'')} key={i}><div className='main-box'><img src={process.env.PUBLIC_URL+'/'+i+".png"} alt=''/></div></div>);
            }
            return res;
        };
        
        return (<div id='apply-page'>
            <Carousel showThumbs={false} autoPlay={true} showStatus={false} infiniteLoop={true}>
                <div>
                    <img src='/imgsilde/1.png' alt=''/>                    
                </div>
                <div>
                    <img src='/imgsilde/2.png' alt=''/>    
                </div>
                <div>
                    <img src='/imgsilde/3.png' alt=''/>    
                </div>
            </Carousel>
            {dipslayBlock()}
        </div>);
    }
}

export default Apply;